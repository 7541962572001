@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.job__wrapper {
  @media screen and (max-width: $large-screen) {
    padding-top: 0 !important;
  }
}

.job__company-header-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: $large-screen) {
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 40px;
  }
}

.job__company-header-empty-placeholder {
  display: none;
  @media screen and (min-width: $large-screen) {
    display: block;
    flex: 1;
  }
}

.job__company-header-buttons {
  display: none;
  @media screen and (min-width: $large-screen) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 8px;
    flex: 1;
  }
}

.job__company-image {
  width: 80px;
  height: 80px;
  flex: 0;
  border-radius: 8px;
}

.job__job-summary-card {
  margin: 24px !important;
  border-radius: 5px !important;
  & > .ant-card-body {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    justify-content: space-between;
    padding: 16px;
  }

  & > .ant-card-body::before,
  .ant-card-body::after {
    display: none;
  }

  @media screen and (min-width: $small-screen) {
    margin: 24px 0 !important;
  }
}

.job__job-summary-card-column {
  flex: 1;

  & > :first-child {
    color: $gray-1;
  }
  & > :last-child {
    color: #fff;
    font-weight: 600;
  }
}

.job__job-information-card {
  margin-bottom: 16px !important;
}

.job__buttons-wrapper {
  margin-top: 16px !important;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  row-gap: 16px;
  column-gap: 16px;

  @media screen and (min-width: $large-screen) {
    flex-direction: row;
    column-gap: 16px;
  }
}

.job__cancel-button {
  font-size: 14px;
}

.job__job-tags-wrapper {
  font-family: "Sora";
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  flex-wrap: wrap;
  row-gap: 8px;
}
