@import "src/theme/variables/colors";

.hover {
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
      background: $primary;
      border-color: $primary;
      color: white;
    }
  }
  &:active {
    opacity: 0.8;
    background: $primary;
    border-color: $primary;

    color: white;
  }
}
