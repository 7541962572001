@import "src/theme/variables/colors";

.ant-card-head {
  background-color: $background-secondary;
  // border-bottom: none;
}

.ant-card-head-title {
  white-space: break-spaces;
  color: $gray-border;
}

.ant-card-body {
  background-color: $background-secondary;
  color: #fff;
  padding-top: 0;
}

.ant-card {
  background: transparent;
  border-radius: 5px;
  border: none;
  overflow: hidden;
}