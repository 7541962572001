@import "src/theme/variables/colors";

.chatLinkButton {
  @media (hover: hover) {
    &:hover {
      color: white;
    }
  }
  &:active {
    color: white;
  }
}
