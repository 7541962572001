.tag {
  padding: 4px 5px;

  background: #5362e9;
  border-radius: 2px;

  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 16px;

  text-transform: uppercase;

  color: #ffffff;
}
