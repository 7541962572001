@import "src/theme/variables/spaces";

.refer__wrapper {
  @media screen and (min-width: $small-screen) {
    max-width: $small-screen;
  }

  @media screen and (min-width: $large-screen) {
    padding: 24px 64px;
    max-width: 100%;
  }
}