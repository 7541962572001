@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.profile-mobile-wrapper {
  font-weight: 600;
}

.profile-mobile-title {
  font-size: 16px;
  margin-bottom: 16px;
}

// .profile-mobile-avatar {
//   height: 128px !important;
//   width: 128px !important;
//   border: 1px solid $gray-2;
//   margin-bottom: 16px;

//   &.profile-mobile__avatar-scrolled {
//     height: 48px !important;
//     width: 48px !important;
//   }
// }

.profile-mobile-fullname {
  font-size: 24px;
  margin-top: 16px;
}

.profile-mobile-buttons {
  margin-bottom: 24px;
}

.profile__close-switch-role {
  position: absolute;
  top: 0;
  left: 0;
  height: 150px;
  width: 100%;
}

.profile__header-responsive-wrapper {
  //position: sticky;
  height: 216px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  top: -48px;
  column-gap: 16px;
  margin-bottom: 16px;
  z-index: 1;

  @media screen and (min-width: $large-screen) {
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0;
  }

  &.profile-header__scrolled {
    background-color: $app-background-color;
    flex-direction: row;
    justify-content: flex-start;
    height: 128px;
    padding-top: 32px;

    & > :first-child {
      display: none;
    }
  }

  transition: all 500ms;
}

.profile__header-responsive {
  position: sticky;
  height: 100px;
  top: 0;
}

.ant-upload {
  width: 100%;
  color: #fff !important;
}

.menu-wrapper {
  padding-left: $app-padding;
  padding-right: $app-padding;
  max-width: $small-screen;

  @media screen and (min-width: $small-screen) and (max-width: $large-screen) {
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (min-width: $large-screen) {
    padding: $app-padding;
  }
}
