@import "/src/theme/variables";
@import "src/theme/override";

$large-grid: 1421px;
$medium-grid: 1208px;
$small-grid-4: 972px;
$small-grid-3: 660px;
$xsmall-grid: 439px;
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.grid {
  // let's calc
  width: 1230px;
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  justify-content: start;

  @media (max-width: $large-grid) {
    width: 937px;
  }
  @media (max-width: $medium-grid) {
    width: calc(180px * 4 + 22px * 3);
    gap: 20px;
  }
  @media (max-width: $small-grid-4) {
    width: calc(180px * 3 + 22px * 2);
  }
  @media (max-width: $small-grid-3) {
    width: calc(180px * 2 + 22px * 1);
  }
  @media (max-width: $xsmall-grid) {
    justify-content: center;
  }
}
.moreFirstSpacing {
  justify-content: center;
}
.card {
  padding: 25px 16px 18px 16px;
  background: #343141;
  border-radius: 19.9149px;
  cursor: pointer;
  height: 368px;
  width: 291px;

  @media (max-width: $medium-grid) and (min-width: $xsmall-grid) {
    padding: 15px 10px 11px 10px;
    width: 180px;
    height: 227.63px;
    border-radius: 12.3185px;
  }

  @media (hover: hover) {
    &:hover {
      background: #4a465b;
    }
  }
  &:active {
    background: #4a465b;
  }
}
.topArea {
  display: flex;
  width: 100%;
  row-gap: 8px;
  margin-bottom: 16px;
  justify-content: space-between;

  @media (max-width: $medium-grid) and (min-width: $xsmall-grid) {
    margin-bottom: 9px;
  }
}
.jobName {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;

  font-size: 18px;
  line-height: 19px;
  /* identical to box height, or 89% */
  color: #ffffff;
  margin-bottom: 6px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 2px;
  @media (max-width: $medium-grid) and (min-width: $xsmall-grid) {
    margin-top: 9px;
    margin-bottom: 4px;

    -webkit-line-clamp: 2;
    font-size: 11.134px;
    line-height: 11px;
  }
}
.logo {
  width: 88px;
  height: 88px;
  border-radius: 9.3px;
  @media (max-width: $medium-grid) and (min-width: $xsmall-grid) {
    width: 54.43px;
    height: 54.43px;

    border-radius: 4.53608px;
  }
}
.earningTag {
  font-family: "Sora";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.391466px;

  color: #121121;

  padding: 0px 10px;
  height: 34px;

  border-radius: 17.6127px;

  @media (max-width: $medium-grid) and (min-width: $xsmall-grid) {
    font-size: 8.65979px;
    line-height: 11px;
    letter-spacing: -0.242144px;
    padding: 0px 8px;
    height: 21px;
    color: #121121;
  }
}
.tagWrapper {
  margin-bottom: 8px;
  @media (max-width: $medium-grid) and (min-width: $xsmall-grid) {
    margin-bottom: 4px;
  }
}

.secondTagWrapper {
  display: flex;
  flex-wrap: nowrap;
  row-gap: 8px;
  overflow-x: scroll;
  @media (max-width: $medium-grid) and (min-width: $xsmall-grid) {
    row-gap: 4px;
  }
}
.salaryTag {
  background: #9a2ed1;
  border-radius: 5px;

  height: 17px;

  font-family: "Roboto Mono";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  color: #ffffff;
  padding: 0px 11px;
  margin-right: 8px;
  text-overflow: ellipsis;
  white-space: noWrap;
  overflow: hidden;

  @media (max-width: $medium-grid) and (min-width: $xsmall-grid) {
    height: 10.52px;

    font-weight: 500;
    font-size: 7.42268px;
    line-height: 10px;
  }
}
.remote {
  height: 17px;
  padding: 0px 11px;
  background: #6f6c99;
  border-radius: 5px;
  height: 17px;
  font-family: "Roboto Mono";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  text-overflow: ellipsis;
  white-space: noWrap;
  overflow: hidden;
  @media (max-width: $medium-grid) and (min-width: $xsmall-grid) {
    height: 10.52px;

    font-weight: 500;
    font-size: 7.42268px;
    line-height: 10px;
  }
}
.location {
  height: 17px;
  font-family: "Roboto Mono";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0px 11px;
  margin-right: 8px;
  margin-bottom: 8px;

  background: #6f6c99;
  border-radius: 5px;
  white-space: noWrap;
  @media (max-width: $medium-grid) and (min-width: $xsmall-grid) {
    height: 10.52px;

    font-weight: 500;
    font-size: 7.42268px;
    line-height: 10px;
  }
}
.details {
  width: 100%;
  text-overflow: ellipsis;
  font-family: "Roboto Mono";
  font-size: 13px;
  line-height: 17px;
  height: 110px;
  color: #ffffff;
  margin-bottom: 12px;
  -webkit-line-clamp: 2;

  @media (max-width: $medium-grid) and (min-width: $xsmall-grid) {
    height: 45px;
    font-size: 8.04124px;
    line-height: 11px;
  }
}

.time {
  font-family: "Roboto Mono";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.454767px;

  color: #f4f4f5;

  @media (max-width: $medium-grid) and (min-width: $xsmall-grid) {
    font-size: 8.65979px;
    line-height: 11px;
    letter-spacing: -0.281299px;
  }
}

.textContainer {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
}
