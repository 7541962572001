@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.conversation-messages-wrapper {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding: 24px;
  //height: calc(100% - 80px - 80px - 70px);
  overflow-y: auto;
  //overscroll-behavior: contain;

  @media screen and (min-width: $small-screen) {
    padding: 24px calc((100% - 476px) / 2);
  }

  @media screen and (min-width: $large-screen) {
    max-width: calc(100vw - #{$navbar-width} - #{$desktop-left-column-width});
    padding: 24px 50px;
    left: $desktop-left-column-width;
    top: 104px;
  }
}

.conversation-message {
  //max-width: 70%;
  background-color: $message-background;
  color: #fff;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  min-width: 120px;
  max-width: 240px;

  @media screen and (min-width: $large-screen) {
    max-width: 320px;
  }
}

.conversation-message-own {
  background-color: $message-own-background;
  align-self: flex-end;
}

.conversation-message-date {
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
}

.conversation-title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
}

.conversation-title-main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.conversation-title-main-name {
  font-size: 20px;
}

.conversation-title-main-last-seen {
  font-size: 12px;
  color: $gray-2;
}

.conversation-message-input-section {
  //background-color: $app-background-color; // TODO
  background-color: $app-background-color;
  border-top: 2px solid $input-border;
  height: 65px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;

  @media screen and (min-width: $small-screen) and (max-width: $large-screen) {
    left: 50%;
    transform: translateX(-50%);
    max-width: $small-screen;
  }
}

.conversation-message-input-wrapper {
  //background-color: transparent;
  background-color: $gray-0;
  --color: #000;
  height: 100%;
  padding: 8px;
  border: none !important;
  &:focus,
  &:hover {
    border: none !important;
  }

  & > input {
    background-color: $gray-0;
    color: #000;
    border: none;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    padding: 0 16px !important;
    &:focus {
      border: none !important;
    }
  }
}

.ant-input-affix-wrapper-focused {
  border: none !important;
  box-shadow: none !important;
}

.conversation-message-input-form-item {
  margin: 0 !important;
  text-align: center;
}

.conversation-message-input-submit-button {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  margin-left: -4px;
  width: 64px;
}

.conversations__conversation-desktop-header {
  @media screen and (max-width: $large-screen) {
    display: none;
  }

  height: 104px;
  width: 100%;
  border-bottom: 1px solid $input-border;
  position: absolute;
  top: 0;
  left: $desktop-left-column-width;
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 12px;
}
