.title {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 16px;
  color: #ffffff;
}

.subtitle,
.footer {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 0.6875rem;
  line-height: 16px;
  color: #dadada;
}

.score {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 0.625rem;
  text-align: right;
  color: #b49ff1;
}

.date {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 0.5625rem;
  line-height: 12px;
  color: #dadada;
}
