@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.select-role__wrapper {
  padding: 0 $app-padding $app-padding $app-padding;
  //background-color: $primary;
  //background: linear-gradient(180deg, rgba(31, 29, 40, 0.8) 34.61%, rgba(31, 29, 40, 0) 100%);
  color: #fff;
  touch-action: none;

  & > .adm-floating-panel-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.select-role__role-cards-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 12px;
}

.select-role__role-card {
  background-color: $gray-0;
  color: $gray-4;
  width: calc(50% - 8px);
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  text-transform: capitalize;
  cursor: pointer;
}