.job-header__wrapper {
  margin-bottom: 24px;
}

.job-header__title {
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 40px;
  font-family: Helvetica, Roboto, sans-serif;
}

.job-header__subtitle {
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  color: #dadada;
}