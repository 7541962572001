.title {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 21px;
  letter-spacing: -0.391466px;

  color: #ffffff;

  padding: 1.5rem;
  padding-bottom: 0;
}

.container {
  display: grid;
  grid-template-rows: 350px 350px;
  margin-bottom: 100px;
  gap: 1rem;
  overflow: scroll;

  > :only-child {
    grid-row: span 2;
    padding-bottom: 2rem;
  }
}

.titleAndList {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0.9rem;
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0 1.5rem;
  height: 100%;
}
