@import "src/theme/variables/colors";

.list-item-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  font-size: 14px;
  background-color: $background-secondary;
  margin-bottom: 16px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;

  & > .anticon {
    margin-right: 16px;
  }

  &.disabled {
    color: $gray-2;
  }

  &.list-item-wrapper-centered {
    justify-content: center;
  }

  &.list-item-wrapper-danger {
    background-color: $error;
    color: #fff;
    border-radius: 40px;
  }
}
