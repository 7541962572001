@import "/src/theme/variables";

.footer {
  background-color: #161844;
  height: 157px;

  display: grid;
  grid-template-areas:
    "logo"
    "social"
    "copyright";

  place-content: center;
  gap: 1.5rem;
  justify-items: center;

  @media screen and (min-width: $small-screen) {
    height: 120px;
    grid-template-areas: "social logo copyright";
    justify-content: space-around;
  }
}

.social {
  grid-area: social;
  display: flex;
  align-items: center;
  gap: 1rem;

  img {
    height: 20px;

    @media screen and (min-width: $small-screen) {
      height: 28px;
    }
  }

  @media screen and (min-width: $small-screen) {
    gap: 1.4rem;
  }
}

.logo {
  grid-area: logo;
  height: 23px;

  @media screen and (min-width: $small-screen) {
    height: 30.61px;
  }
}

.copyright {
  grid-area: copyright;

  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;

  color: #ccd2e9;

  @media screen and (min-width: $small-screen) {
    font-size: 14px;
    line-height: 24px;
  }
}
