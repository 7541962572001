.degraded {
  background: linear-gradient(
    89.46deg,
    #5362e9 9.25%,
    #5362e9 51.1%,
    #b127c8 92.85%
  );

  @media (hover: hover) {
    &:hover {
      background: #7e48d9;
    }
  }
  &:active {
    background: #7e48d9;
  }
}

.filter-white {
  filter: brightness(0%) invert(100%);
}
