.outsideOval {
  position: absolute;

  $size: 30.53px;
  width: $size;
  height: $size;
  border-radius: 50%;

  background: radial-gradient(
    43.97% 43.97% at 50% 51.18%,
    rgba(156, 129, 234, 0.01) 0%,
    rgba(156, 129, 234, 0.48) 35.94%,
    rgba(156, 129, 234, 0.17) 100%
  );

  display: grid;
  place-content: center;

  top: 50%;
  transform: translateY(-50%);

  > .insideOval {
    width: 10.78px;
    height: 10.78px;
    border-radius: 50%;

    background: #9c81ea;
    box-shadow: 0px 0px 5.4541px #1f1c45;
  }
}
