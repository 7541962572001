@import "src/theme/variables/colors";

.ant-btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.ant-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  //gap: 16px;
  & > svg {
    display: initial;
  }
}

.ant-btn-dangerous,
.ant-btn-dangerous:hover {
  background-color: $error !important;
  border-color: $error !important;
}

.ant-btn-block {
  //border-radius: 40px;
}

.ant-btn-lg {
  height: 54px;

  & > span {
    line-height: 23px !important;
  }
}

.ant-btn-round.ant-btn-lg {
  height: 54px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

.ant-btn-primary[disabled] {
  background-color: $background-secondary !important;
  border-color: $background-secondary !important;
  cursor: default;
  color: #71707a !important;

  @media (hover: hover) {
    &:hover {
      color: #71707a !important;
    }
  }
  &:active {
    color: #71707a !important;
  }
}
