.emptyMessageTitle {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.391466px;
  color: #ffffff;
}

.emptyMessageSubtitle {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #a8a8a8;
}

.emptyMessageList {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.73605px;
  color: #ffffff;
}
