@import "src/theme/variables/spaces";
@import "src/theme/variables/colors";

.adm-floating-panel {
  touch-action: none;
  background-color: #16151d;
  max-width: $small-screen;
  overflow: hidden;
  z-index: 9999;
  @media screen and (min-width: $small-screen) {
    //
  }
}

.adm-floating-panel .adm-floating-panel-content {
  overflow: hidden;
}