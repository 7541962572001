@import "src/theme/variables/spaces";

.adm-dialog-footer {
  display: flex;
}

.adm-dialog-action-row {
  flex: 1;
}

.adm-text-area {
  color: #000 !important;
}

.adm-dialog-wrap {
  @media screen and (min-width: $small-screen) {
    max-width: 360px;
  }
}

.adm-dialog-body {
  background-color: #121121 !important;
}

.adm-dialog-action-row {

}