.emptyTitle {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.73605px;

  color: #ffffff;
}

.title {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 21px;
  letter-spacing: -0.391466px;

  color: #ffffff;

  padding: 1.5rem;
  padding-bottom: 0;
}

.container {
  height: 100%;
  display: grid;
  grid-template-columns: 420px;
  grid-template-rows: 50% 50%;
  gap: 1rem;
  overflow: hidden;

  > :only-child {
    grid-row: span 2;
    padding-bottom: 2rem;
  }
}

.titleAndList {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0.9rem;
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0 1.5rem;
}
