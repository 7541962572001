$error: #E63D70;
$success: #70e63d;
$selected-tag: #1890ff;
$primary: #5362E9;
$green: #00F4C8;
//$primary: #9C81EA;
//$primary: #49ab6c;
//$secondary: #6E57B0;
//$secondary: #5362E9;
$secondary: #9C81EA;
//$app-background-color: #1F1D28;
$app-background-color: #121121;
$background-secondary: #343141;
$input-background: #4A465B;
$input-border: #4A465B;
$gray-border: #928CA6;
$gray-text: #928CA6;
$pink: #C37AF6;


$gray-0: #dadada;
$gray-1: #c4c4c4;
$gray-2: #898a8d;
$gray-3: #676767;
$gray-4: #313141;
$gray-label: #ADA7C1;

$message-background: #89839B;
$message-own-background: #413E4F;
