.container {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;

  > div {
    overflow-y: hidden;
  }
}

.header {
  display: flex;
  justify-content: space-around;

  color: white;
}

.headerTitleContainer {
  width: 100%;
  display: grid;
  place-content: center;
  padding: 0.3rem;

  border-bottom: 2px solid #4a465b;

  &.selected {
    border-bottom: 3px solid #9c81ea;
  }
}

.headerTitle {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 37px;
  letter-spacing: -0.391466px;

  color: #ffffff;

  //   border-bottom: #ffffff 1px solid;
}
