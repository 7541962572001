@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.jobs {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  @media screen and (max-width: 1300px) {
    grid-template-columns: repeat(1, 1fr);

    // display: grid;
    // // grid-template-columns: repeat(2, 1fr);
    // gap: 1rem;

    // grid-template-columns: repeat(1, 1fr);
    // grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);

    // display: grid;
    // // grid-template-columns: repeat(2, 1fr);
    // gap: 1rem;

    // grid-template-columns: repeat(1, 1fr);
    // grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.location {
  font-family: "Roboto Mono";
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  color: $gray-0;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.title {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;

  color: #ffffff;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.subtitle {
  text-transform: capitalize;
  font-size: 13px;
  color: #8a8a8a;
  line-height: 16px;
  margin-top: 4px;
}

.score {
  color: #b49ff1;
  font-size: 12px;
}

.date {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;

  letter-spacing: -0.03em;

  color: #ffffff;
}

.rewardTag {
  max-width: 130px;

  padding: 0px 10px;
  height: 18px;
  font-family: "Roboto Mono";
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #2d5c54;
  background-color: white;
  text-align: center;
  text-transform: uppercase;
  border-radius: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.salaryTag {
  padding: 0px 10px;
  max-width: 130px;

  height: 18px;

  background: #9a2ed1;
  border-radius: 4px;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7.69173px;
  font-size: 12px;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.hover {
  transition: none;
  color: white;

  @media (hover: hover) {
    &:hover {
      background: #4a465b;
      color: white;
    }
  }
  &:active {
    background: #4a465b;
    color: white;
  }
}
