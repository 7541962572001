@import "src/theme/variables/colors";

// Restyle default tags
.custom-ant-tag {
  border-radius: 2px !important;
  padding: 12px !important;
  font-size: 13px !important;
  background-color: transparent;
  color: $gray-text;
  border: 1px solid $gray-border;
  text-transform: capitalize;

  &.selectable-tag {
    @media (hover: hover) {
      &:hover {
        background-color: $primary !important;
        border-color: $primary;
        color: #fff !important;
        cursor: pointer !important;
      }
    }
    &:active {
      background-color: $primary !important;
      border-color: $primary;
      color: #fff !important;
      cursor: pointer !important;
    }
  }
}

.ant-tag {
  & > svg,
  span {
    display: inline-flex;
  }
}

.profile-tag {
  text-transform: uppercase !important;
  padding: 2px 4px 0 !important;
}

// Change colors for selected tag
.checked-tag {
  background-color: $primary !important;
  color: #fff !important;
  border: none;
}
