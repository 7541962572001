.container {
  position: relative;
  background-color: #343141;
  border-radius: 5px;
  padding: 17px 12px 11px;
  display: grid;
  grid-template-columns: 70% auto;
  gap: 0.8rem;
  height: auto;
  @media (hover: hover) {
    &:hover {
      background: #4a465b;
    }
  }
  &:active {
    background: #4a465b;
  }
  .left {
    color: #fff;
    .title {
      font-family: "Roboto Mono";
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 16px;
      color: #ffffff;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 4px;
    }

    .subtitle {
      font-family: "Roboto Mono";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #dadada;
      text-transform: capitalize;
      margin-bottom: 2px;
    }
  }
}
