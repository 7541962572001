@import "../../theme/variables/colors";

.hired-card-wrapper {
  & > .ant-card-body {
    padding: 12px;
  }

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

.hired-section-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  margin-bottom: 24px;

  & > .ant-avatar {
    cursor: pointer;
  }
}

.hired-section-header__title {
  font-size: 21px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
}

.hired-section-header__subtitle {
  font-size: 14px;
  font-weight: 400;
  color: $gray-1;
}

.hired__employment-info__payment {
  border: 1px solid #{$gray-border};
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 24px;
  font-size: 13px;
  & > :first-child {
    color: #979797;
  }
  & > :last-child {
    font-weight: 600;
  }
}

.hired__employment-status {
  border-radius: 5px !important;
  border: none !important;
  padding: 2px 4px !important;
  font-weight: 500;
  margin-bottom: 24px !important;
  color: #fff !important;
}

.color-status-onboarding {
  background-color: $secondary !important;
}

.hired__employment-info-section {
  margin-bottom: 24px;
}

.hired__employment-info-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  //& > :first-child {
  color: $gray-1;
  //}
  & > :last-child {
    color: #fff;
    font-weight: 500;
  }
}

.hired__referred-by-section {
  font-size: 16px;
  color: $gray-1;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  & > a {
    color: #fff !important;
  }
}

.hired__disabled-payment-info {
  font-size: 12px;
  color: $gray-2;
}
