.hover {
  @media (hover: hover) {
    &:hover {
      background-color: #5362e9;
      color: white;
      border-color: #5362e9;
    }
  }
  &:active {
    background-color: #5362e9;
    color: white;
    border-color: #5362e9;
  }
}
.blackHover {
  @media (hover: hover) {
    &:hover {
      border-color: #2a3594;
    }
  }
  &:active {
    border-color: #2a3594;
  }
}

.opacityHover {
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
  &:active {
    opacity: 0.8;
  }
}
