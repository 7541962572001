.button {
  padding: 0.5rem 1rem;
  width: 100%;

  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 17px;

  text-align: center;

  color: #ffffff;

  white-space: nowrap;
}

.small {
  padding: 0.5rem 1rem;
}

.medium {
  padding: 0.5rem 1.5rem;
}

.large {
  padding: 0.9rem 2rem;
}

.primary {
  background-color: #5362e9;
}

.secondary {
  background-color: #4a465b;
}

.danger {
  background-color: #e63d70;
}

.degraded {
  background: linear-gradient(
    89.46deg,
    #5362e9 9.25%,
    #5362e9 51.1%,
    #b127c8 92.85%
  );

  @media (hover: hover) {
    &:hover {
      background: #7e48d9;
    }
  }
  &:active {
    background: #7e48d9;
  }
}

.ghost {
  background: transparent;
  border: 1.3px solid #7e8bb6;
  color: #7e8bb6;

  &:hover {
    background: #ffffff;
    border: 1.3px solid #ffffff;
    color: #121121;
  }
}

.rounded {
  border-radius: 40px;
}

.square {
  border-radius: 5px;
}

.simple {
  background-color: transparent;
  color: #5362e9;
}
