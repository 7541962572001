@import "src/theme/variables/colors";

.job__view-edit-job {
  color: #fff !important;
  font-size: 16px !important;
  background-color: transparent !important;
  border: none !important;
}

.job__more-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  pointer-events: auto;

  & > .job__modal-title {
    font-size: 18px;
  }

  & > .job__modal-subtitle {
    color: $gray-1;
    text-transform: capitalize;
  }

  & > img {
    width: 120px;
    height: 120px;
    margin-bottom: 16px;
  }

  & > a {
    width: 240px;
    background-color: $background-secondary;
    border-radius: 5px;
    margin-top: 16px;
    padding: 12px;
    color: #fff;

    & > :first-child {
      margin-right: 8px;
    }
  }
}
