@import "src/theme/variables/colors";

.search-input__wrapper {
  border: 1px solid $input-border;
  border-radius: 12px;
  color: #fff;
  background-color: transparent;
  width: 100%;
  padding: 16px;

  &:focus-visible, &:focus {
    outline: none;
  }

  &:focus-visible {
    border-color: $secondary;
  }
}