.hover {
  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
  &:active {
    opacity: 1;
  }
}
