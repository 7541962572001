@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.notification-preview-wrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;
  height: 120px;
  padding: 16px;
  color: $gray-3;
  border: 1px solid $input-border;
  margin-bottom: 16px;
  border-radius: 8px;

  @media screen and (min-width: $large-screen) {
    height: 100%;
  }
}

.notification-preview-center {
  flex: 1;
  white-space: pre-line;
  text-overflow: ellipsis;
  width: 160px;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  & > .notification-text {
    font-weight: 600;
  }

  & > .notification-text-link {
    color: $gray-3;

    @media (hover: hover) {
      &:hover {
        color: $secondary !important;
      }
    }
    &:active {
      color: $secondary !important;
    }
  }
}

.notification-preview-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  max-height: 64px;
  row-gap: 2px;
  font-size: 12px;
}

.stacked-avatar-wrapper {
  height: 100%;
  width: 48px;
  padding-top: 20px; // (120 - 2*16 - 48) / 2

  @media screen and (min-width: $large-screen) {
    padding-top: 0;
    height: 48px;
  }
}

.stacked-avatar-left {
  //
}

.stacked-avatar-right {
  left: 16px;
  top: -16px;
}

.notification-preview-unread {
  color: $gray-0;

  & > .notification-text-link {
    color: #fff;
    font-weight: 800;

    @media (hover: hover) {
      &:hover {
        color: $secondary !important;
      }
    }
    &:active {
      color: $secondary !important;
    }
  }
}

.notifications__wrapper {
  padding: 0 $app-padding;

  @media screen and (min-width: $small-screen) and (max-width: $large-screen) {
    padding: 0;
  }

  @media screen and (min-width: $large-screen) {
    padding: 32px 64px;
  }
}

.notification__view-button {
  border: 1.5px solid $secondary;
  color: $secondary;
  border-radius: 6px;

  @media (hover: hover) {
    &:hover {
      background-color: $secondary;
      color: #fff;
    }
  }
  &:active {
    background-color: $secondary;
    color: #fff;
  }
}

.notification__date-title {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 24px;
  color: $gray-1;
  &:not(:first-of-type) {
    margin-top: 32px;
  }

  @media screen and (min-width: $large-screen) {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 32px;
    &:not(:first-of-type) {
      margin-top: 48px;
    }
  }
}
