.ant-avatar-image {
  background: #fff;
}

.ant-avatar-square {
  border-radius: 5px;
}

.ant-avatar {
  cursor: pointer;
}