.card {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  align-items: center;
  max-height: 102px;
  background: #343141;
  border-radius: 5px;
  padding: 16px 13px;
  color: white;

  @media (hover: hover) {
    &:hover {
      background: #4a465b;
    }
  }
  &:active {
    background: #4a465b;
  }
}

.center {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 100%;

  // display: flex;
  // flex-direction: column;
  // align-items: start;
  > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.right {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 100%;
  align-items: center;
  justify-items: stretch;
}
