.container {
  // position: relative;
  height: 52px;

  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 8px;

  border: 1px solid #4a465b;
  border-radius: 12px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.input {
  appearance: none;
  // width: calc(90% - 12px);
  background: transparent;

  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.391466px;

  color: #ffffff;

  &::placeholder {
    color: #ffffffc4;
  }

  // disable the native style from IE
  &::-ms-expand,
  &::-ms-clear {
    display: none;
  }
}

.icon {
  width: 20px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
