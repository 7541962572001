@import "override";
@import "src/theme/variables/spaces";
@import "src/theme/variables/colors";

.filters-section {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

// Remove borders
.borderless {
  --border-top: none !important;
  --border-bottom: none !important;
  --border-inner: none !important;
}

.base-padding {
  padding: $app-padding;
}

.responsive-padding {
  padding: $app-padding;
  max-width: $small-screen;

  @media screen and (min-width: $small-screen) {
    padding: $app-padding 0;
  }

  @media screen and (min-width: $large-screen) {
    padding: 0 0 32px 0;
    max-width: 100%;
  }
}

.regular-padding {
  padding: $app-padding;
  min-height: 100%;
  max-width: $small-screen;

  @media screen and (min-width: $small-screen) {
    padding: $app-padding 0;
  }

  @media screen and (min-width: $large-screen) {
    padding: 0 0 32px 0;
    max-width: 100%;
  }
}

.regular-padding-horizontal {
  padding: 0 $app-padding;

  @media screen and (min-width: $small-screen) {
    padding: 0;
  }

  @media screen and (min-width: $large-screen) {
    padding: 0 0 $app-padding 0;
    max-width: 100%;
  }
}

.padding-vertical {
  //padding-top: $app-padding;
  //padding-bottom: $app-padding;
  padding: $app-padding 0;

  @media screen and (min-width: $large-screen) {
    padding-top: 0;
    max-width: 100%;
  }
}

.desktop__main-padding {
  max-width: $small-screen;
  height: 100%;

  @media screen and (min-width: $large-screen) {
    min-height: 100%;
    //padding: 24px 64px;
    max-width: 100%;
  }
}

.children-bottom-margin {
  & > :not(:last-child) {
    margin-bottom: 16px;
    @media screen and (min-width: $large-screen) {
      margin-bottom: 24px;
    }
  }
}

.absolute-navbar {
  position: absolute;
  left: $app-padding;
  top: $app-padding;
}

.ant-mobile-tag-selected {
  background-color: $primary;
  border-color: $primary;
  color: #fff;
  cursor: default !important;
}

.ant-tag-group {
  & > .ant-tag {
    margin-bottom: 8px !important;
  }
}

.custom-input-textarea {
  background-color: $input-border !important;
  border: 1px solid $input-border;
  border-radius: 12px;
  color: $gray-0;
  box-shadow: none;
  margin-top: 8px;
}

.custom-input-border-bottom {
  @media (hover: hover) {
    &:hover {
      border: none;
      box-shadow: none;
    }
  }
  &:active {
    border: none;
    box-shadow: none;
  }
  & > .ant-input-wrapper {
    & > input {
      background-color: transparent !important;
      border: none !important;
      padding: 0 !important;
      color: $gray-0 !important;
      box-shadow: none !important;

      @media (hover: hover) {
        &:hover {
          box-shadow: none !important;
        }
      }
      &:active {
        box-shadow: none !important;
      }
    }

    & > .ant-input-group-addon {
      background-color: transparent;
      border: none;
      color: $gray-1;

      & > .hidden-addon {
        display: none;
      }
    }
  }
}

.custom-form-input-item-has-error {
  & > .custom-form-input-item-label {
    & > label {
      color: $error !important;
    }
  }

  & > .custom-form-input-item-control {
    & > .custom-form-input-item-control-input {
      & > .custom-form-input-item-control-input-content {
        & > .custom-input-border-bottom {
          border-color: $error !important;
          color: $error !important;
        }
      }
    }
  }

  & > .custom-form-input-item-label,
  .custom-form-textarea-item-label {
    color: $error;
  }
}

.flex-centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.centered {
  text-align: center;
  justify-content: center;
}

.custom-input {
  border-radius: 8px !important;
  //border: none !important;
  border: 1px solid #4a465b !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 12px !important;

  & > .ant-input {
    background-color: transparent;
    color: $gray-0;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      border: none;
    }
  }

  & > .ant-input-prefix {
    color: $gray-0 !important;
  }

  & > .ant-input-suffix {
    & > .ant-badge {
      color: $gray-1;
    }
  }
}

.ant-form-item-label > label {
  height: 16px;
}

.custom-form-input-item {
  margin: 0 0 24px 0;
  padding: 8px;
  background-color: $input-background;
  flex-flow: column;
  flex-wrap: wrap;
  vertical-align: top;
  transition: margin-bottom 0.3s 0.017s linear;
  border-radius: 4px;
}

.custom-form-input-item-explain-error {
  color: $error;
}

.custom-form-textarea-item {
  margin: 0 0 24px 0;
  flex-flow: column;
  flex-wrap: wrap;
  vertical-align: top;
}

.custom-form-input-item-label,
.custom-form-textarea-item-label {
  text-transform: uppercase;
  color: $gray-label;
  font-size: 12px !important;
}

.custom-form-tag-group-label {
  color: #fff;
  font-size: 12px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.custom-form-label-wrapper {
  text-transform: uppercase;
  color: $gray-label;
  font-size: 12px !important;
  display: flex;
  justify-content: space-between;

  & > h4 {
    color: #fff !important;
  }
}

.sticky-navbar {
  position: sticky;
  top: 0;
  background-color: $app-background-color;
  z-index: 9;
}

.mobile-navbar-relative {
  position: relative;
  background-color: inherit;
  padding: 0;
}

.mobile-not-logged-in {
  .adm-nav-bar-right {
    width: auto;
  }
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 700;
}

.custom-simple-input {
  background-color: transparent;
  height: 52px;
  border: 1px solid $input-border;
  border-radius: 5px;
  color: #fff;
  &::placeholder {
    color: $input-border;
  }
}

.button__background-color {
  border: none;
  //border-color: $input-border;
  background-color: $app-background-color;
}

.status-tag {
  color: $background-secondary;
  padding: 4px;
  font-weight: 600;
  border-radius: 5px;
}

.clickable {
  cursor: pointer;
}

.margin-bottom-desktop {
  @media screen and (min-width: $small-screen) {
    margin-bottom: 24px;
  }

  @media screen and (min-width: $large-screen) {
    margin-bottom: 32px;
  }
}

.padding-mobile {
  padding: 24px;

  @media screen and (min-width: $small-screen) {
    padding: 0 0 1px 0; // TODO: fix this hack
  }
}

.button__secondary {
  background-color: $secondary;
  border-color: $secondary;
  color: #fff;
}

.capitalize {
  text-transform: capitalize;
}

.color__gray-1 {
  color: $gray-1 !important;
}

.marginless {
  margin: 0 !important;
}

.paddingless {
  // TODO: DEPRECATE WHEN POSSIBLE
  padding: 0 !important;
}

.responsive-buttons-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  @media screen and (min-width: $large-screen) {
    flex-direction: row;
    column-gap: 8px;
    justify-content: space-between;
    margin-top: 24px;
  }
}

.gradient-background,
.gradient-background:hover {
  background: linear-gradient(
    89.46deg,
    #5362e9 9.25%,
    #6c38b5 51.1%,
    #b127c8 92.85%
  ) !important;
  border: none;
}

.tall-button {
  height: 54px;
  font-size: 16px;
}

.color__secondary {
  color: $secondary !important;
}

.card-group {
  & > .ant-card {
    margin-bottom: -8px;
  }

  & > .ant-card:first-of-type {
    & > .ant-card-head {
      padding-top: 24px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }

  & > .ant-card:last-of-type {
    & > .ant-card-body {
      padding-bottom: 40px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}

.img__rounded {
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  background: $app-background-color;
}

::-webkit-scrollbar-thumb {
  background: $background-secondary;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.icon-color__white {
  & > path {
    fill: #fff;
  }
}

.icon-color__primary {
  & > path {
    fill: $primary;
  }
}

.icon-color__secondary {
  & > path {
    fill: $secondary;
  }
}

.selected-list-item-desktop {
  @media screen and (min-width: $large-screen) {
    position: absolute;
    left: -24px;
    border-radius: 50%;
    transform: translateY(6px);
    background: radial-gradient(
      43.97% 43.97% at 50% 51.18%,
      rgba(156, 129, 234, 0.01) 0%,
      rgba(156, 129, 234, 0.48) 35.94%,
      rgba(156, 129, 234, 0.17) 100%
    );
    width: 40px;
    height: 40px;
  }
}

.align-self-center {
  align-self: center;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.section-title {
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 12px;
}

.salary-tag {
  background-color: #951ed0;
  color: #fff;
  border: none;
  padding: 4px 8px;
  font-weight: 600;
}

.color-primary {
  color: $primary;

  @media (hover: hover) {
    &:hover {
      color: $primary;
    }
  }
  &:active {
    color: $primary;
  }
}

.underline {
  text-decoration: underline;
}
