@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

// Make progress bar on NavBar wider
.adm-nav-bar-title {
  flex: 6 !important;
  font-size: 16px;
  font-weight: 700;
}

// Contains the role selection cards
.roles-wrapper {
  display: grid;
}

// Wrapper around whole onboarding form
.onboarding-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 12px;

  @media screen and (min-width: $large-screen) {
    padding: 0;
  }
}

// Top navbar for the onboarding page, with progress bar
.onboarding-navbar {
  margin-bottom: 32px;
}

// Wrapper around each onboarding step
.onboarding-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  //position: absolute;
  padding: $app-padding;
  width: 100%;
  height: 100%;

  //max-width: calc(100vw - #{2 * $app-padding});

  @media screen and (min-width: $small-screen) {
    padding: $app-padding 0 0 0;
  }

  @media screen and (min-width: $large-screen) {
    position: relative;
    max-width: 100%;
  }
}

.onboarding-step__title {
  font-size: 22px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 8px;
}

.onboarding-step__subtitle {
  font-size: 12px;
  color: #fff;
  margin-bottom: 32px;
}

// Footer where the onboarding buttons live
.onboarding-footer {
  margin-top: 24px;
  margin-bottom: 24px;
}

.onboarding-confirm-button {
  position: absolute;
  bottom: 0;
  width: calc(100% - 48px);
  max-width: $small-screen;

  @media screen and (min-width: $large-screen) {
    // position: fixed;
    // bottom: 48px;
    // max-width: calc(100% - 48px - #{$navbar-width});
  }
}

.fixed-bottom {
  position: absolute;
  bottom: calc(24px + 70px);
  //width: calc(100% - 48px);
  width: 100%;
  max-width: $small-screen;

  @media screen and (min-width: $large-screen) {
    bottom: 24px;
    max-width: 100%;
  }
}

.onboarding-section {
  //position: absolute;
  width: 100%;
  //max-width: calc(100% - #{2 * $app-padding});
  //height: calc(100% - 69px - 24px); // 100% - navbar - padding bottom
  overflow-y: auto;
  //overscroll-behavior-y: contain;
}

.onboarding-title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 48px;
  color: #fff;
}
