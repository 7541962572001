@import "src/theme/variables";

.burgerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 6px;
  cursor: pointer;
  @media screen and (min-width: $medium-screen) {
    display: none;
  }

  z-index: 2;
}

.burgerLine {
  height: 1.5px;
  width: 22px;
  background-color: #fff;
}

.content {
  position: absolute;
  width: 100%;
  left: 0;
  top: -300px;
  opacity: 0;
  background-color: #070e27;
  transition: all ease 0.2s;

  z-index: 1;

  & > div {
    padding: 24px;
    color: #7e8bb6;
    border-bottom: 1px solid #232740;

    & > span {
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          color: #fff;
        }
      }
      &:active {
        color: #fff;
      }
    }
  }

  @media screen and (min-width: $small-screen) {
    display: none;
  }
}

.contentOpen {
  top: 96px;
  opacity: 1;
}
