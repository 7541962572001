@import "/src/theme/variables";

.container {
  height: 96px;
  border-bottom: 1px solid #232740;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 96px;
  margin-bottom: 70px;

  @media screen and (max-width: $medium-screen) {
    padding: 0 24px;
  }

  position: sticky;
  top: 0;

  background-color: #121121;
  z-index: 20;
}