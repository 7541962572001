@import "../../theme/variables/colors";
@import "../../theme/variables/spaces";

.profile-preview__header-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  @media screen and (min-width: $large-screen) {
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 24px;
  }
}

.profile-preview__header-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > .full-name {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }

  & > .job-title {
    font-size: 14px;
  }

  & > .location {
    color: $gray-1;
  }

  & > .social {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    & > :not(:last-child) {
      margin-right: 16px;
    }
  }
}

.profile-preview__header {
  width: 100%;
  height: 100vw;
  max-height: $small-screen;
  padding: $app-padding;
  display: flex;

  @media screen and (max-width: $large-screen) {
    background-color: rgba(0, 0, 0, 0.4);
  }

  @media screen and (min-width: $large-screen) {
    height: 100%;
    padding: 0;
    margin-bottom: 32px;
  }
}

.profile-preview__section {
  padding: 24px;

  @media screen and (min-width: $large-screen) {
    padding: 0;
    margin-top: 24px;
  }
}
