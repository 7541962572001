@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.job-card-wrapper {
  // margin-bottom: 16px;
  background-color: $gray-4;

  & > .adm-card-body {
    display: flex;
    column-gap: 12px;
  }
}

.job-card-center {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  min-height: 60px;
  overflow: hidden;
  & > .job-card-footer {
    font-size: 13px;
    //color: $gray-2;
    line-height: 16px;
    & > a {
      text-decoration: underline;
    }
  }

  &.narrow {
    max-width: 180px;
  }
}

.job-card-right {
  display: flex;
  flex-direction: column;
  align-items: start;

  & > .job-card-footer {
    color: $gray-2;
    font-size: 13px;
    line-height: 16px;
  }
}

.job-card-title {
  //font-size: 12px;
  line-height: 16px;
  font-size: 14px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.job-card-subtitle {
  text-transform: capitalize;
  font-size: 13px;
  color: #8a8a8a;
  line-height: 16px;
  margin-top: 4px;
}

.adm-card-body {
  & > .ant-avatar-image {
    align-self: center;
    border-radius: 5px;
  }
}

.job-application__score {
  color: #b49ff1;
  font-size: 12px;
}

.job-application__date {
  color: $gray-1;
  font-size: 11px;
}
