@import "src/theme/variables/colors";

.selectedItem {
  position: absolute;
  left: calc(-24px - 37px / 2);
}

.title {
  line-height: 16px;
  font-size: 0.875rem;
  text-transform: capitalize;
}

.subtitle {
  font-size: 0.6875rem;
  color: #dadada;
  text-decoration: underline;
}

.footer {
  color: #dadada;
  font-size: 0.6875rem;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.score {
  color: #b49ff1;
  font-size: 12px;
}

.date {
  color: $gray-1;
  font-size: 0.6rem;
  line-height: 12px;
}
