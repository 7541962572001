@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.conversation-preview-wrapper {
  display: flex;
  padding: 16px 24px;
  color: $gray-1;
  cursor: pointer;
  border-bottom: 1px solid $input-border;

  @media (hover: hover) {
    &:hover {
      background-color: #343144;
    }
  }
  &:active {
    background-color: #343144;
  }
  @media screen and (min-width: $large-screen) {
    &.selected {
      background-color: $input-border;
    }
  }
}

.adm-swipe-action {
  &:not(:last-of-type) {
    border-bottom: 1px solid $input-border;
  }
}

.conversation-preview-unread {
  color: #fff;
  font-weight: 600;
}

.conversation-preview-center {
  flex: 1;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.conversation-preview-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.adm-card-body {
  & > .ant-avatar-image {
    align-self: center;
    border-radius: 5px;
  }
}

.conversation-preview-title {
  font-size: 14px;
}

.conversation-preview-subtitle {
  font-size: 12px;
}

.conversation-preview-footer {
  font-size: 12px;
  font-weight: 400;
  color: $gray-1;
  white-space: nowrap;
  overflow: hidden;
  width: 150px;
  text-overflow: ellipsis;
}

.unread-messages {
  background-color: $secondary;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat__search-wrapper {
  padding: 16px;
  border-bottom: 1px solid $input-border;

  @media screen and (min-width: $large-screen) {
    padding: 24px 16px;
    height: 104px;
  }
}
