.opacityHover {
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
  &:active {
    opacity: 0.8;
  }
}
.underlineHover {
  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
  &:active {
    text-decoration: underline;
  }
}
.primaryTextHover {
  @media (hover: hover) {
    &:hover {
      color: #5362e9;
    }
  }
  &:active {
    color: #5362e9;
  }
}
