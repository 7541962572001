.slider {
  -webkit-appearance: none;
  height: 2px;
  background: #e9e9e9;
  border-radius: 5px;
  background-image: linear-gradient(#5362e9, #5362e9);
  background-repeat: no-repeat;
  width: 100%;
  cursor: grab;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #e9e9e9;
  border: 1px solid #5362e9;
  cursor: grab;
  // box-shadow: 0 0 6px 0 #5362e9;
  transition: background 0.3s ease-in-out;
}

.slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.slider::-webkit-slider-thumb:hover {
  box-shadow: #5362e9 0px 0px 0px 4px;
}

.slider::-webkit-slider-thumb:active {
  box-shadow: #5362e9 0px 0px 0px 7px;
  transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
