.jobTitle {
  font-family: "Helvetica";
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;

  color: #ffffff;

  mix-blend-mode: normal;
  opacity: 0.9;
  white-space: nowrap;
  display: block;
}

.jobSubTitle {
  font-family: "Roboto Mono";
  font-size: 12px;
  line-height: 16px;
  color: #dadada;
  white-space: nowrap;
  display: block;
}

.location {
  font-weight: 400;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: #dadada;
}

.reference {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;

  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.noReference {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
  padding: 0;
  margin: 0;
  width: 50%;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  text-align: center;
}
.table {
  background-color: darkblue;
}

.tableRow {
  background-color: darkblue;
}

.firstJobEmptyTitle {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.391466px;
  color: #ffffff;
}

.firstJobEmptySubtitle {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #a8a8a8;
}

.emptyJobsTitle {
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.391466px;
  text-align: center;
  color: #ffffff;
  margin-top: 100px;
}

.emptyJobsSubtitle {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #a8a8a8;
  width: 18rem;
}

.hover {
  @media (hover: hover) {
    &:hover {
      background-color: #4b4857;
    }
  }
  &:active {
    background-color: #4b4857;
  }
}
