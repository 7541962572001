@import "src/theme/variables/colors";

.stake__wrapper {
  background-color: #2E274B;
  color: #fff;
  touch-action: none;
}

.stake__body {
  height: calc(100% - 85px - 48px - 24px);
  overflow-y: scroll;
  padding: 0 24px;
  //overscroll-behavior: contain;
}

.stake__balance {
  display: flex;
  column-gap: 8px;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 16px;

  & > :first-child {
    color: $primary;
  }
}

.stake__input-wrapper {
  height: 96px;
  width: 100%;
  border: 1px solid $gray-1;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  margin-bottom: 16px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.stake__input {
  background-color: transparent !important;
  color: #fff !important;
  width: 100% !important;

  & > .ant-input-number-input-wrap {
    width: 100%;

    & > input {
      font-size: 24px;
    }
  }
}

.stake__input-column {
  flex: 1;
}

.stake__currency-column {
  font-size: 20px;
}

.stake__currency {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
  & > img {
    margin-bottom: 2px;
  }
}

.stake__apply-button {
  position: absolute;
  left: 24px;
  bottom: 24px;
  width: calc(100% - 48px);
}