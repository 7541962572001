$app-padding: 24px;
$full-mobile-width: calc(100% + 2 * 24px);
$full-mobile-width-absolute: calc(100% - 2 * 24px);
$padding-with-tab-bar: calc(24px + 70px);
$height-with-tabbar: calc(100% - 70px);

$navbar-mobile-height: 70px;
$navbar-width: 272px;
$topbar-desktop-height: 115.25px;
$topbar-desktop-height-scrolled: 60px;
$topbar-mobile-height: 70px;
$desktop-main-padding: 64px;
$desktop-left-column-width: 420px;
$desktop-left-column-width-extended: 350px;

$small-screen: 476px;
$medium-screen: 768px;
$large-screen: 1024px;
