.add-note-wrapper {
  display: flex;
  justify-content: flex-end;

  > div {
    flex: 1;
  }
}

.profile-photo-mask {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
}
