@import "src/theme/variables/colors";

.hover {
  p {
    @media (hover: hover) {
      &:hover {
        color: $secondary;
      }
    }
  }
  p {
    &:active {
      color: $secondary;
    }
  }
}
.secondaryHover {
  @media (hover: hover) {
    &:hover {
      color: $secondary;
    }
  }
  &:active {
    color: $secondary;
  }
}
.opacityHover {
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
  &:active {
    opacity: 0.8;
  }
}
.opacityAndColorHover {
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
      background: $primary;
      border-color: $primary;
      color: white;
    }
  }
  &:active {
    opacity: 0.8;
    background: $primary;
    border-color: $primary;

    color: white;
  }
}

.whiteColorHover {
  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
    }
    p {
      &:hover {
        color: white;
      }
    }
  }
  &:active {
    opacity: 0.5;
  }
  p {
    &:active {
      color: white;
    }
  }
}
