@import "src/theme/variables/colors";

.application-referrer {
  font-size: 18px;
}

.application-referrer-link {
  text-decoration: underline;
  color: #fff;

  @media (hover: hover) {
    &:hover {
      color: $secondary;
      text-decoration: underline;
    }
  }
  &:active {
    color: $secondary;
    text-decoration: underline;
  }
}
