.hover {
  @media (hover: hover) {
    &:hover {
      background-color: #4b4857;
    }
  }
  &:active {
    background-color: #4b4857;
  }
}
