@import "../../theme/variables/colors";
@import "../../theme/variables/spaces";

.mobileWrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: center;
  .mobileInnerWrapper {
    padding: 0px 25px;

    width: 460px;
    background-color: $app-background-color;
    border-top: 2px solid $gray-border;
    height: 70px;
    display: flex;
    gap: 2.2rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 16px;
    justify-content: space-between;
    text-align: center;

    color: #d0c9d6;
    @media screen and (max-width: $large-screen) {
      overflow-x: scroll;
    }
    .mobileItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 70px;
      width: 100px;
      justify-content: center;
      gap: 2.7px;
    }
  }
}

.desktopWrapper {
  background: linear-gradient(
    180deg,
    rgba(38, 34, 80, 0.69) 0%,
    rgba(38, 34, 80, 0.85) 57.44%,
    #17163b 100%
  );

  width: $navbar-width;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 16px 72.39px 29.75px;
  .responsiveHeight {
    body::-webkit-scrollbar {
      width: 20px;
    }

    body::-webkit-scrollbar-thumb {
      background-color: red;
      outline: 1px solid slategrey;
    }
  }
}

.opacityHover {
  color: white;
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
      color: white;
    }
  }
  &:active {
    opacity: 0.8;

    color: white;
  }
}

.svgHover {
  @media (hover: hover) {
    &:hover {
      color: #9c81ea;
      svg {
        path {
          fill: #9c81ea;
        }
      }
    }
  }
  &:active {
    color: #9c81ea;
    svg {
      path {
        fill: #9c81ea;
      }
    }
  }
}
