@import "src/theme/variables/colors";

.questionLabel {
  display: flex;
  justify-content: space-between;
}

.deleteQuestionIcon {
  cursor: pointer;
}

.addedJobTitle {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  letter-spacing: -0.391466px;
  color: #ffffff;
}

.addedJobSubtitle {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.391466px;
  color: #e9e9e9;

  width: 70%;
}

.doneButton {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  width: 30%;
}

.hover {
  @media (hover: hover) {
    &:hover {
      background-color: #5362e9;
      color: white;
      border-color: #5362e9;
    }
  }
  &:active {
    background-color: #5362e9;
    color: white;
    border-color: #5362e9;
  }
}

.blackHover {
  @media (hover: hover) {
    &:hover {
      border-color: #2a3594;
    }
  }
  &:active {
    border-color: #2a3594;
  }
}

.opacityHover {
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
      color: white;
    }
  }
  &:active {
    opacity: 0.8;
    color: white;
  }
}
