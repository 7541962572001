.hover {
  cursor: pointer;
  transition: opacity ease-in-out 0.2s;
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
      color: white;
    }
  }
  &:active {
    color: white;

    opacity: 0.8;
  }
}

.linear {
  background: linear-gradient(
    90deg,
    #5362e9 29.17%,
    #9a2ed1 71.14%,
    #e63d70 126.83%
  ) !important;
}

.tagHover {
  @media (hover: hover) {
    &:hover {
      background: #5362e9;
      border-color: #5362e9;
      color: white;
    }
  }
  &:active {
    background: #5362e9;
    border-color: #5362e9;
    color: white;
  }
}

.mobileTitle {
  font-family: "Sora";
  font-weight: 600;
  font-size: 38px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(
    90.72deg,
    #5362e9 -9.33%,
    #6738ed 48.13%,
    #b028c8 99.23%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  @media screen and (max-width: 340px) {
    font-size: 32px;
    line-height: 40px;
  }
}

.fontColor {
  color: #9c81ea !important;
}
