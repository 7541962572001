@import "/src/theme/variables/spaces";

.selectedItem {
  position: absolute;
  left: calc(-24px - 38px / 2);
  top: calc(50% - 18px);
}

.wrapper {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  // padding-left: $app-padding;
  // padding-right: $app-padding;
  // max-width: $small-screen;

  // @media screen and (min-width: $small-screen) and (max-width: $large-screen) {
  //   padding-left: 0;
  //   padding-right: 0;
  // }

  // @media screen and (min-width: $large-screen) {
  //   padding-top: $app-padding;
  // }
}
