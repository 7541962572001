
// https://gist.github.com/spemer/a0e218bbb45433bd611e68446523a00b
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
*::-webkit-scrollbar {
  //background-color: #9C81EA;
  width: 2px;
  height: 2px;
  display: none;
  position: fixed;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  display:none;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display:none;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #9C81EA;
  border-radius:16px;
  border: 2px solid #9C81EA;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: #9C81EA;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #9C81EA;
  border: 4px solid #9C81EA;
}