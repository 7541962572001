@import "src/theme/variables/colors.scss";
@import "src/theme/variables/spaces.scss";
//  @fontsource/sora doesn't seem to display proper weights
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .icon-fill-white {
    path {
      fill: #fff;
    }
  }

  .icon-fill-violet {
    path {
      fill: #9c81ea;
    }
  }
}

input {
  &:focus {
    outline: none;
  }
}

body {
  font-family: "Roboto Mono", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  color: #fff;
  //max-width: $small-screen;
  background-color: $app-background-color;
  margin: auto;

  @media screen and (min-width: $large-screen) {
    margin: 0;
    font-size: 16px;
  }
}

#root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

@media (hover: hover) {
  a:hover {
    color: $secondary;
  }
}
a:active {
  color: $secondary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff !important;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root:root {
  --adm-color-primary: #5362e9;
  --adm-color-success: #9c81ea;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-white: #ffffff;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-border-color: #eeeeee;
  --adm-font-size-main: 13px;
  --adm-color-text: #fff;
  --active-line-height: 3px;

  --adm-font-family: -apple-system, blinkmacsystemfont, "Helvetica Neue",
    helvetica, segoe ui, arial, roboto, "PingFang SC", "miui",
    "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
  -webkit-transition-delay: 99999s;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.markdown {
  code {
    font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
      Liberation Mono, monospace;
    border-radius: 6px;
    font-size: 85%;
    margin: 0;
    padding: 0.2em 0.4em;
    background-color: rgba(175, 184, 193, 0.2);
  }
  ol {
    list-style: decimal;
    li {
      list-style: decimal;
      margin: 0px 32px;
    }
  }
  ul {
    list-style-type: disc;
    li {
      list-style-type: disc;
      margin: 0px 16px;
    }
  }
}
