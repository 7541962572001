@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.desktop-topbar__wrapper {
  height: $topbar-desktop-height;
  width: calc(100% - #{$navbar-width});
  border-bottom: 1px solid $input-border;
  // padding: 0px 53px 0px 58px;
  padding: 41.45px 53px 31.8px 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: $navbar-width;
  background-color: $app-background-color;
  z-index: 10;
  transition: height 0.1s ease;
}

.desktop-topbar__wrapper-override {
  z-index: 9999;
}

.desktop-topbar__scrolled {
  height: $topbar-desktop-height-scrolled;
}

.desktop-topbar__not-logged-in {
  left: 0;
  width: 100%;

  > .topbar__title {
    display: grid;
    grid-template-rows: 1fr;
    width: 100%;
  }
}

.topbar__title {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > svg {
    cursor: pointer;
    margin-right: 8px;
  }

  & > .topbar__title-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    text-transform: capitalize;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.desktop-topbar__right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notifications-tag {
  width: 55px;
  height: 31px;
  mix-blend-mode: normal;
  opacity: 0.8;
  border-radius: 15.2715px;
  background-color: $success;
  color: black;
  border-radius: 15px;
  padding: 4px 12px;
  cursor: pointer;
  margin-right: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4.36328px 33.8154px #34a853;

  font-family: "Helvetica";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: white;

  &.notifications-tag__unread {
    background-color: $error;
    color: white;
    box-shadow: 0px 4.36328px 33.8154px #ba1358;
  }
}

.user-avatar {
  margin-right: 10px !important;
}

.mobile-topbar__wrapper {
  height: $topbar-mobile-height;
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: $app-background-color;
  z-index: 10;
  transition: height 0.1s ease;
  // border: 2px solid #6f6981;

  @media screen and (min-width: $small-screen) {
    padding: $app-padding calc((100% - #{$small-screen}) / 2);
  }
}

.mobile-topbar__left {
  min-width: 24px;
}

.mobile-topbar__right {
  min-width: 24px;
}

.mobile__message-icon {
  cursor: pointer;
}

.mobile__notifications-tag {
  background-color: $success !important;
  color: #000 !important;
  border: none !important;
  border-radius: 15px !important;
  padding: 4px 12px !important;
  cursor: pointer;
  margin-right: 16px !important;

  &.mobile__notifications-tag__unread {
    background-color: $error !important;
    color: #fff !important;
  }
}
