@import "/src/theme/variables";

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 45px;
  overflow-x: hidden;

  display: flex;
  align-items: center;

  z-index: inherit;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 128s linear infinite;

  z-index: inherit;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.rewardsPaidNumber {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #ffffff;

  z-index: inherit;
}

.outstandingRewardsNumber {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #00f4c8;
  z-index: inherit;
}

.endorsedText {
  max-width: 456px;

  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  /* or 125% */

  letter-spacing: -0.03em;

  color: #ffffff;

  font-size: 38px;
  line-height: 48px;
  text-align: center;

  @media screen and (min-width: $small-screen) {
    font-size: 64px;
    line-height: 80px;
    text-align: left;
  }
}

.endorsedSubtext {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;

  letter-spacing: -0.02em;

  color: #b2b1b3;

  font-size: 12px;
  line-height: 16px;
  text-align: center;

  @media screen and (min-width: $small-screen) {
    font-size: 20px;
    line-height: 26px;
    text-align: left;
  }
}

.startEndorsingText {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 53px;
  letter-spacing: -0.03em;

  color: #ffffff;
}

.endorsingPeopleText {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.03em;

  color: #5362e9;

  @media screen and (min-width: $small-screen) {
    font-size: 17.7791px;
    line-height: 22px;
  }
}

.howDorseWorksText {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #ffffff;

  @media screen and (min-width: $small-screen) {
    font-size: 42px;
    line-height: 53px;
  }
}

.howItWorksTitles {
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  letter-spacing: -0.03em;

  color: #ffffff;

  text-align: center;

  @media screen and (min-width: $small-screen) {
    font-size: 24px;
    line-height: 30px;
  }
}

.searchTitle1 {
  color: #ffffff;
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;

  letter-spacing: -0.03em;

  font-size: 14px;
  line-height: 18.46px;

  &::placeholder {
    font-size: 24px;
  }

  @media screen and (min-width: $medium-screen) {
    font-size: 42px;
    line-height: 53px;

    ::placeholder {
      font-size: 42px;
    }
  }
}

.searchTitle2 {
  color: #9d9d9d;
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;

  letter-spacing: -0.03em;

  font-size: 24px;
  line-height: 30px;

  @media screen and (min-width: $small-screen) {
    font-size: 42px;
    line-height: 53px;
  }
}

.searchInput {
  appearance: none;
  background: transparent;

  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  font-size: 14px;
  line-height: 18.46px;

  &::placeholder {
    font-size: 14px;
  }

  letter-spacing: -0.70765px;

  color: #ffffff;

  height: 52px;

  &::placeholder {
    color: #ffffff;
    font-size: 14px;
    line-height: 18.46px;

    @media screen and (min-width: $medium-screen) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  @media screen and (min-width: $medium-screen) {
    font-size: 24px;
    line-height: 32px;
  }
}

.searchIcon {
  font-size: 20px;

  @media screen and (min-width: $medium-screen) {
    font-size: 36.15px;
  }
}

.endorsingGrid {
  display: grid;
  gap: 3.5rem;

  grid-template-columns: 1fr;

  @media (min-width: $small-screen) {
    grid-template-columns: 1fr auto 1fr auto 1fr;
  }
}

.arrow {
  @apply px-1;
  display: none;
  align-self: center;

  @media (min-width: $medium-screen) {
    display: block;
  }
}

.lastText {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 16.2731px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.03em;

  text-align: center;

  color: #ffffff;

  @media screen and (min-width: $medium-screen) {
    font-size: 28px;
    line-height: 35px;
  }
}

.skillsListSpacing {
  --space: 32px;

  @screen lg {
    --space: 0;
  }

  > :first-child {
    margin-left: var(--space);
  }

  > :last-child {
    margin-right: var(--space);
  }
}

.jobSkillsTitle {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.566039px;
  color: #ffffff;

  @media screen and (min-width: $small-screen) {
    font-size: 24px;
    line-height: 32px;
  }
}
