@import "src/theme/variables/colors";

.referral__referrer-status-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: 1px solid $input-border;
  padding: 16px;
  border-radius: 5px;
}

.referral__referrer-status {
  min-width: 100px;
}