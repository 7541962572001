@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.container {
  // @media (max-width: $large-screen) {
  //   @apply overflow-y-scroll hide-scrollbar;
  // }
}

.jobs {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  max-width: $small-screen;

  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-moz-viewport-scroll {
    display: none;
  }
}

.skillsListSpacing {
  --space: 1rem;

  @screen lg {
    --space: 0;
  }

  > :first-child {
    margin-left: var(--space);
  }

  > :last-child {
    margin-right: var(--space);
  }
}

.button {
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      background-color: #9c81ea;
      color: #fff;
      svg {
        path {
          fill: white;
        }
      }
    }
  }
  &:active {
    background-color: #9c81ea;
    color: #fff;
    svg {
      path {
        fill: white;
      }
    }
  }
}
.bannerBackground {
  background: linear-gradient(
    90.2deg,
    #5660e5 -6.27%,
    #673ce1 50.73%,
    #b028c8 105.08%
  );
}

.hover {
  @media (hover: hover) {
    &:hover {
      background-color: #9c81ea;
      color: white;
    }
  }
  &:active {
    background-color: #9c81ea;
    color: white;
  }
}
.scaleHover {
  @media (hover: hover) {
    &:hover {
      transform: scale(1.05);
    }
  }
  &:active {
    transform: scale(1.05);
  }
}
