@import "src/theme/variables/colors";

.social-link-wrapper {
  display: flex;
  width: 34px;
  border-radius: 50%;
  background-color: #fff;
  color: #000;

  & > .anticon {
    padding: 7px;
    font-size: 18px;
  }

  &.disabled {
    color: $gray-1 !important;
  }

  @media (hover: hover) {
    &:hover {
      color: $secondary;
    }
  }
  &:active {
    color: $secondary;
  }
}
