@import "src/theme/variables/colors";

.adm-result {
  background-color: transparent;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-result-title, .adm-result-title {
  color: #fff;
  font-weight: 700;
  font-size: 35px;
}

.adm-result-description {
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  padding: 0 40px;
}

.ant-result-subtitle {
  color: $gray-1;
}