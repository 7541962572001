@import "~antd/dist/antd.css";
@import "theme/variables/colors";
@import "theme/variables/spaces";
@import "styles/anything";
@import "styles/spinner";
@import "styles/scrollbar";
@import "styles/toastify-overrides";

@import "theme";
@import "styles/antd-overrides.scss";

html {
  min-width: 100vw;
  min-height: 100vh;
}

.App {
  width: 100%;
  height: 100%;
  background: $app-background-color;
}

.mainWrapper {
  position: absolute;
  top: $topbar-mobile-height;
  height: calc(100% - #{$topbar-mobile-height} - #{$navbar-mobile-height});
  width: 100%;
  overflow-y: auto;
  &.mainWrapper-notLoggedIn {
    height: calc(100% - #{$topbar-mobile-height});
  }

  @media screen and (min-width: $small-screen) {
    padding: 0 calc((100% - #{$small-screen}) / 2);
  }

  @media screen and (min-width: $large-screen) {
    padding: 0;
    top: $topbar-desktop-height;
    left: $navbar-width;
    height: calc(100% - #{$topbar-desktop-height});
    width: calc(100% - #{$navbar-width});
    // overflow: hidden;
  }
}
