@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.desktop-layout__wrapper {
  //height: 100%;
  & > :not(:last-child) {
    border-right: 1px solid $input-border;
  }

  display: flex;
  flex-direction: row;
  overflow-y: hidden;

  @media screen and (min-width: $large-screen) {
    height: calc(100vh - 80px);
  }
}

.desktop-layout__left-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  // padding: 24px;
  overflow: scroll;
  width: 40%;
  min-width: $desktop-left-column-width;
}

.desktop-layout__main-column {
  height: calc(100vh - 80px - 70px);
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (min-width: $large-screen) {
    padding: 24px 48px;
    height: 100%;
  }
}

.desktop-layout__right-column {
  height: 100%;
  padding: 24px;
}
