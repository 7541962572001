@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.wrapper {
  position: relative;
  // height: 128px;
  // width: 128px;
}

.avatar {
  width: 100%;
  height: 100%;
  border: 1px solid $gray-2;
}

.fileInput {
  z-index: 9;
  position: absolute;
  bottom: 0;
  right: 10px;

  .icon {
    background-color: $primary;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;

    & > svg {
      height: 100%;
    }
  }
}

.changeButtonText {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.54541px;

  color: #ffffff;
}

.removeButtonText {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.54541px;

  color: #5362e9dd;

  @media (hover: hover) {
    &:hover {
      color: #5362e9;
      text-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);
    }
  }
  &:active {
    color: #5362e9;
    text-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);
  }
}

.hover {
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
      color: white;
    }
  }
  &:active {
    opacity: 0.8;
    color: white;
  }
}
