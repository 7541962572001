.card {
  padding: 16px 20px;
  background: #343141;
  .title {
    font-family: "Sora";
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ada7c1;
    margin-bottom: 16px;

    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .contents {
    font-family: "Roboto Mono";
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;

    @media (max-width: 1024px) {
      font-size: 11px;
      line-height: 16px;
    }
  }
}
