@import "src/theme/variables/colors";

@media (hover: hover) {
  .ant-input:hover {
    color: $secondary;
  }
}
.ant-input:active {
  color: $secondary;
}

input[type="file"] {
  display: none;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border-color: $error;
}
