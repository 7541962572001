@import "../../theme/variables/spaces";
@import "../../theme/variables/colors";

.auth__brand-title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 24px;
}

.auth__brand-title {
  font-size: 40px;
}

.auth__button-social {
  background-color: #fff !important;
  font-family: Roboto;
  color: $gray-2 !important;
  font-weight: 600 !important;
  display: flex !important;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  margin-top: 16px;

  @media (hover: hover) {
    &:hover {
      box-shadow: none !important;
    }
  }
  &:active {
    box-shadow: none !important;
  }
}

.auth__button-auth {
  color: #fff !important;
}

.auth__white-text {
  color: #fff;
  margin-top: 16px;
  font-family: "Roboto Mono for Powerline", sans-serif;
  & > a {
    margin-left: 8px;
  }
}
