@import "src/theme/variables/colors";

.message-card-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

.message-avatar {
  flex: none;
  margin-right: 8px;
}

.message-name {
  font-weight: 600;
  margin-bottom: 8px;
}

.message-text {
  margin-bottom: 8px;
}

.message-card {
  background-color: $message-background;
  color: #000;
  font-size: 12px;
  width: 100%;
}

.message-own-card {
  background-color: $message-own-background;
  width: 100%;
}

.message-footer {
  color: $gray-2;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
