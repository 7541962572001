.firstJobEmptyTitle {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.391466px;
  color: #ffffff;
}

.firstJobEmptySubtitle {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  text-align: center;
  color: #a8a8a8;
}
