@import "src/theme/variables/colors";

.ant-select {
  //border: 1px solid $input-border;
  border-radius: 5px;

  & > .ant-select-selector {
    height: 100% !important;

    & > .ant-select-selection-search {
      height: 100% !important;
      display: flex;
      align-items: center;
    }

    & > .ant-select-selection-item {
      align-self: center;
    }
  }
}

.ant-select-arrow {
  width: 16px;
  height: 16px;
  transform: translate(-2px, -2px);
  display: none;
}
