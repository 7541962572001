@import "src/theme/variables/colors";

.ant-switch {
  background-color: #6E6980 !important;
  box-shadow: none !important;
}

.ant-switch-checked {
  & > .ant-switch-handle:before {
    background-color: $primary;
  }
}

.ant-switch-handle:before {
  background-color: #343141;
}