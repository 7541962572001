.pageTitle {
  font-family: "Roboto mono";
  font-style: normal;
  font-weight: 700;
  font-size: 32.7246px;
  line-height: 38px;
  /* identical to box height */

  color: #ffffff;

  mix-blend-mode: normal;
  opacity: 0.9;
}

.userName {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}

.hover {
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
  &:active {
    opacity: 0.8;
  }
}
