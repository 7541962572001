@import "src/theme/variables/colors";

.slider {
  --main-color: rgb(104, 117, 217);
  --main-color-hover: rgb(104, 117, 217, 0.2);
  --track-color: #ddd;

  width: 100%;
}

.sliderTrack {
  /* Top value to align your track to the center of your thumb */
  top: 8px;
  /* thickness of the track */
  height: 4px;
  /* default color of your track */
  background: var(--track-color);

  &.sliderTrack-0 {
    /* color of the track before the thumb */
    background: var(--main-color);
  }
}

.sliderThumb {
  cursor: pointer;
  /*color for the thumb */
  background: var(--main-color);
  /* shape of the thumb: circle */
  width: 20px;
  height: 20px;
  border-radius: 100%;
  /* remove default outline when selected */
  outline: none;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0 0 0 8px var(--main-color-hover);
    }
  }
  &:active {
    box-shadow: 0 0 0 8px var(--main-color-hover);
  }
}

.hover {
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
      background: $primary;
      border-color: $primary;
      color: white;
    }
  }
  &:active {
    opacity: 0.8;
    background: $primary;
    border-color: $primary;

    color: white;
  }
}
